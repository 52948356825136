import html2Canvas from "html2canvas";
import JsPDF from "jspdf";

const downloadPDF = (ele: any, pdfName: string): any => {
  const title = pdfName;
  html2Canvas(document.querySelector(ele), {
    allowTaint: true,
    useCORS: true,
  }).then(function (canvas) {
    const contentWidth = canvas.width;
    const contentHeight = canvas.height;
    const pageHeight = (contentWidth / 592.28) * 841.89;
    let leftHeight = contentHeight;
    let position = 0;
    const imgWidth = 595.28;
    const imgHeight = (592.28 / contentWidth) * contentHeight;
    const pageData = canvas.toDataURL("image/jpeg", 1.0);
    const PDF = new JsPDF(undefined, "pt", "a4");
    if (leftHeight < pageHeight) {
      PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
    } else {
      while (leftHeight > 0) {
        PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
        leftHeight -= pageHeight;
        position -= 841.89;
        if (leftHeight > 0) {
          PDF.addPage();
        }
      }
    }
    PDF.save(title + ".pdf");
  });
};

export default {
  downloadPDF,
};
