



































































































































































import api from "@/api/index"; //ABP API接口
import { Component, Vue } from "vue-property-decorator";
import { CustomerType, OrderMainDto, SelectListItem } from "@/api/appService";
import moment from "moment";
import getPdf from "@/utils/exportPDF";

@Component({
  name: "Invoice"
})
export default class Invoice extends Vue {
  dataId?: string;
  detail: OrderMainDto = {
    // fkReceivingAddressMapping:{} as CustomerReceivingAddressMappingDto
  };
  loading = true;
  orderReceivingMethodList = [] as SelectListItem[];
  detailList = [] as any[];

  subTotal = 0;

  created() {
    if (this.$route.params.id) {
      this.dataId = this.$route.params.id;
      this.fetchDetail();
    }
    this.fetchOrderReceivingMethodList();
  }

  // 获取详情
  async fetchDetail() {
    this.subTotal = 0;
    await api.orderMain.get({ id: this.dataId }).then((res) => {
      this.loading = false;
      this.detail = { ...res };
      this.detail.detailList?.map((item) => {
        this.$set(item, "total", item.price! * item.quantity!);
        this.subTotal += item.price! * item.quantity!;
      });
      //添加空白行
      this.detailList = this.detail.detailList?.concat([
        {},
        {},
      ]) as any[];
    });
  }

  getCustomerTypeText() {
    if (this.detail.fkCustomer) {
      if (this.detail.fkCustomer.customerType === CustomerType.Enterprise) {
        return "Business";
      } else if (this.detail.fkCustomer.customerType === CustomerType.Member) {
        return "Membership";
      } else if (
        this.detail.fkCustomer.customerType === CustomerType.Ordinary
      ) {
        return "Person";
      }
    }
    return "";
  }

  async fetchOrderReceivingMethodList() {
    await api.enumService
      .getValues({ typeName: "OrderReceivingMethod" })
      .then((res) => {
        this.orderReceivingMethodList = res;
      });
  }

  formatDate(date: any) {
    if (date) {
      return moment(String(date)).format("MM/DD/YYYY");
    }
    return "";
  }

  handleDownLoad() {
    getPdf.downloadPDF(
      "#pdfPrint",
      ((this as any).$l.getLanguageText("invoice.order") as string) +
      this.detail.orderCode +
      ((this as any).$l.getLanguageText("invoice.invoice") as string)
    );
  }
}
